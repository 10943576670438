module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.11_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital Spiders","short_name":"Digital Spiders","start_url":"/","background_color":"#010b24","theme_color":"#010b24","display":"minimal-ui","icon":"src/images/favicon.svg","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"4802dc7e734a409622c0a317dc4a63f1"},
    },{
      plugin: require('../node_modules/.pnpm/@sentry+gatsby@7.85.0_gatsby@5.12.11_react@18.2.0/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_patch_hash=gljdgzchyenww4c7avqxteajo4_gatsby@5.12.11_r_ueds2kkzputijo6q752o34mcg4/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W92FZF9J","enableWebVitalsTracking":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.11_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.3.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
